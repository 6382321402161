import React from 'react'
import { BrowserRouter, Routes, Route, Navigate, Outlet } from 'react-router-dom'
import './App.css'
import Home from './components/home/HomeV2'
import Header from './components/navigation/Header'
import Login from './components/login/Login'
import isAuthenticated from './auth/AuthenticateUser'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import ImageUpload from './components/features/ImageUpload'

// App Screens
import GuessThePersonalityHome from './components/apps/guess-the-personality/Home'
import NDAExamPracticeHome from './components/apps/nda-exam-practice/Home'
import EraExpertHome from './components/apps/era-expert/Home'
import CodingInterviewHome from './components/apps/coding-interview/Home'
import GeographyGKQuizHome from './components/apps/geography-gk-quiz/Home'
import HistoryGKQuizHome from './components/apps/history-gk-quiz/Home'
import UPSCinFactHome from './components/apps/upsc-infact/Home'
import Profile from './components/features/Profile'

function App() {
    const PrivateWrapper = () => {
        return isAuthenticated() ? <Outlet /> : <Navigate to='/login' />
    }
    return (
        <BrowserRouter>
            <div>
                <ToastContainer />
                <Header />
                <Routes>
                    <Route exact path='/login' element={<Login />} />
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/' element={<Home />} />
                        <Route exact path='/profile' element={<Profile />} />
                        <Route exact path='/image-upload' element={<ImageUpload />} />
                    </Route>
                    <Route element={<PrivateWrapper />}>
                        <Route exact path='/apps/guess-the-personality/home' element={<GuessThePersonalityHome />} />
                        <Route exact path='/apps/nda-exam-practice/home' element={<NDAExamPracticeHome />} />
                        <Route exact path='/apps/era-expert/home' element={<EraExpertHome />} />
                        <Route exact path='/apps/coding-interview/home' element={<CodingInterviewHome />} />
                        <Route exact path='/apps/geography-gk-quiz/home' element={<GeographyGKQuizHome />} />
                        <Route exact path='/apps/history-gk-quiz/home' element={<HistoryGKQuizHome />} />
                        <Route exact path='/apps/upsc-infact/home' element={<UPSCinFactHome />} />
                    </Route>
                </Routes>
            </div>
        </BrowserRouter>
    )
}

export default App
