import { FaBookmark, FaCalendarAlt, FaUser, FaCode, FaGlobe, FaBook, FaCloud } from 'react-icons/fa';
import { FaGun } from "react-icons/fa6";
import { BiSolidHomeAlt2 } from "react-icons/bi";


export const navItems = [
    {
        value: 'Home',
        route: '',
        icon: <BiSolidHomeAlt2 />
    },
    {
        value: 'Image Upload',
        route: 'image-upload',
        icon: <FaCloud />
    },
];

export const navItems2 = [
    {
        value: 'UPSC inFact',
        route: 'apps/upsc-infact/home',
        icon: <FaBookmark />
    },
    {
        value: 'Era Expert',
        route: 'apps/era-expert/home',
        icon: <FaCalendarAlt />
    },
    {
        value: 'Guess Personality',
        route: 'apps/guess-the-personality/home',
        icon: <FaUser />
    },
    {
        value: 'Coding Interview',
        route: 'apps/coding-interview/home',
        icon: <FaCode />
    },
    {
        value: 'NDA Practice',
        route: 'apps/nda-exam-practice/home',
        icon: <FaGun />
    },
    {
        value: 'Geography GK Quiz',
        route: 'apps/geography-gk-quiz/home',
        icon: <FaGlobe />
    },
    {
        value: 'History GK Quiz',
        route: 'apps/history-gk-quiz/home',
        icon: <FaBook />
    },
];

export const logoutItem = {
    value: 'Profile',
    route: 'profile',
    icon: <FaUser />
};
