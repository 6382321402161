import React, { useState, useEffect } from 'react';
import { TextField, Table, TableBody, TableCell, TableHead, TableRow, IconButton, Chip, Fab, Button, Modal, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import MainLayout from '../../navigation/MainLayout';
import { BASE_URL } from '../../../api/Constants';

const INITIAL_CATEGORIES = ['All', 'Without Image', 'Politics', 'Science', 'Art', 'History', 'Literature', 'Philosophy', 'Revolution', 'Exploration', 'Sports', 'Celebrities', 'Business', 'Anime'];

function GuessThePersonalityHome() {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [personalityModalOpen, setPersonalityModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedPersonality, setSelectedPersonality] = useState(null);
  const [newPersonality, setNewPersonality] = useState({ name: '', category: '', imageURL: '' });
  const [personalities, setPersonalities] = useState([]);
  const [categories, setCategories] = useState(INITIAL_CATEGORIES);

  // Fetch all personalities from the API
  const getPersonalities = () => {
    axios.get(`${BASE_URL}/api/v1/guess-personality/personalities`)
      .then(response => {
        setPersonalities(response.data.data.list);
      })
      .catch(error => {
        console.error('Error fetching personalities:', error);
      });
  };

  useEffect(() => {
    getPersonalities();
  }, []);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  // Count the number of personalities in each category
  const getCategoryCounts = () => {
    const categoryCounts = { 'All': personalities.length, 'Without Image': personalities.filter(personality => !personality.imageURL).length };
    personalities.forEach(personality => {
      categoryCounts[personality.category] = (categoryCounts[personality.category] || 0) + 1;
    });
    return categoryCounts;
  };

  const categoryCounts = getCategoryCounts();

  // Filter personalities based on search, category, and the "Without Image" filter
  const filteredPersonalities = personalities.filter((personality) => {
    const matchesSearchQuery = personality.name.toLowerCase().includes(searchQuery.toLowerCase()) || personality.category.toLowerCase().includes(searchQuery.toLowerCase());
    if (selectedCategory === 'All') return matchesSearchQuery;
    if (selectedCategory === 'Without Image') return matchesSearchQuery && !personality.imageURL;
    return matchesSearchQuery && personality.category === selectedCategory;
  });

  const handleAddPersonality = () => {
    axios.post(`${BASE_URL}/api/v1/guess-personality/personalities`, newPersonality)
      .then(() => {
        setPersonalityModalOpen(false);
        getPersonalities();
      })
      .catch(error => {
        console.error('Error adding personality:', error);
      });
  };

  const handleEditPersonality = () => {
    axios.put(`${BASE_URL}/api/v1/guess-personality/personalities/${selectedPersonality._id}`, selectedPersonality)
      .then(() => {
        setEditModalOpen(false);
        getPersonalities();
      })
      .catch(error => {
        console.error('Error editing personality:', error);
      });
  };

  const handleDeletePersonality = () => {
    axios.delete(`${BASE_URL}/api/v1/guess-personality/personalities/${selectedPersonality._id}`)
      .then(() => {
        setDeleteDialogOpen(false);
        getPersonalities();
      })
      .catch(error => {
        console.error('Error deleting personality:', error);
      });
  };

  return (
    <MainLayout>
      <div className="p-4">

        {/* Search Bar */}
        <TextField
          label="Search by name or category"
          variant="outlined"
          value={searchQuery}
          onChange={handleSearch}
          fullWidth
          sx={{ mb: 4 }}
        />

        {/* Chip section to show category counts */}
        <div className="flex flex-wrap gap-2 mb-4">
          {categories.map((category, index) => (
            <Chip
              key={index}
              label={`${category} - ${categoryCounts[category] || 0}`}
              color={selectedCategory === category ? 'primary' : 'default'}
              variant={selectedCategory === category ? 'filled' : 'outlined'}
              onClick={() => handleCategoryClick(category)}
              style={{ cursor: 'pointer' }}
            />
          ))}
        </div>

        {/* Personalities Table */}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Image URL</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredPersonalities.map((personality, index) => (
              <TableRow key={index}>
                <TableCell>{personality.name}</TableCell>
                <TableCell>{personality.category}</TableCell>
                <TableCell>{personality.imageURL || 'No Image'}</TableCell>
                <TableCell>
                  <IconButton onClick={() => {
                    setSelectedPersonality(personality)
                    setEditModalOpen(true)
                  }
                  }>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => {
                    setSelectedPersonality(personality)
                    setDeleteDialogOpen(true)
                  }
                  }>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {/* FAB Button to Add Personality */}
        <Fab
          color="primary"
          aria-label="add"
          onClick={() => setPersonalityModalOpen(true)}
          style={{ position: 'fixed', bottom: '20px', right: '20px' }}
        >
          <AddIcon />
        </Fab>

        {/* Add Modal */}
        <Modal open={personalityModalOpen} onClose={() => setPersonalityModalOpen(false)}>
          <div className="bg-gray-100 rounded-lg shadow-lg p-8 overflow-y-auto mx-auto mt-20 max-w-4xl w-11/12 h-5/6">
            <h2 className="text-2xl font-bold mb-4">Add Personality</h2>
            <div className="space-y-4">
              <TextField
                label="Name"
                value={newPersonality.name}
                onChange={(e) => setNewPersonality({ ...newPersonality, name: e.target.value })}
                fullWidth
                variant="outlined"
              />
              <TextField
                label="Category"
                value={newPersonality.category}
                onChange={(e) => setNewPersonality({ ...newPersonality, category: e.target.value })}
                fullWidth
                variant="outlined"
              />
              <TextField
                label="Image URL"
                value={newPersonality.imageURL}
                onChange={(e) => setNewPersonality({ ...newPersonality, imageURL: e.target.value })}
                fullWidth
                variant="outlined"
              />
            </div>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddPersonality}
              className="w-full mt-6"
              sx={{ mt: 6 }}
            >
              Submit
            </Button>
          </div>
        </Modal>

        {/* Edit Modal */}
        <Modal open={editModalOpen} onClose={() => setEditModalOpen(false)}>
          <div className="bg-gray-100 rounded-lg shadow-lg p-8 overflow-y-auto mx-auto mt-20 max-w-4xl w-11/12 h-5/6">
            <h2 className="text-2xl font-bold mb-4">Edit Personality</h2>
            <div className="space-y-4">
              <TextField
                label="Name"
                value={selectedPersonality?.name || ''}
                onChange={(e) => setSelectedPersonality({ ...selectedPersonality, name: e.target.value })}
                fullWidth
                variant="outlined"
              />
              <TextField
                label="Category"
                value={selectedPersonality?.category || ''}
                onChange={(e) => setSelectedPersonality({ ...selectedPersonality, category: e.target.value })}
                fullWidth
                variant="outlined"
              />
              <TextField
                label="Image URL"
                value={selectedPersonality?.imageURL || ''}
                onChange={(e) => setSelectedPersonality({ ...selectedPersonality, imageURL: e.target.value })}
                fullWidth
                variant="outlined"
              />
            </div>
            <Button
              variant="contained"
              color="primary"
              onClick={handleEditPersonality}
              className="w-full mt-6"
              sx={{ mt: 6 }}
            >
              Save Changes
            </Button>
          </div>
        </Modal>

        {/* Delete Confirmation Dialog */}
        <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
          <DialogTitle>Delete Personality</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this personality?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeletePersonality} color="secondary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </MainLayout>
  );
}

export default GuessThePersonalityHome;
