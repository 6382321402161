import React, { useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../../api/Constants";

const GenerateWithAI = () => {
  const [paragraph, setParagraph] = useState("");
  const [response, setResponse] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setResponse("");

    try {
      const result = await axios.post(
        `${BASE_URL}/api/v1/generate-year-facts`,
        { paragraph }
      );
      if (result.status === 200) {
        setResponse("AI generated successfully");
      } else {
        setResponse("Failed to generate response. Please try again.");
      }
    } catch (error) {
      console.error("Error generating with AI:", error);
      setResponse("Error: Unable to generate using AI.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-4xl mx-auto mt-10 bg-white p-6 rounded-lg shadow-md">
      <h2 className="text-2xl font-bold text-blue-600 mb-4">
        Generate using AI
      </h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label
            htmlFor="paragraph"
            className="block text-gray-700 font-medium mb-2"
          >
            Enter Paragraph
          </label>
          <textarea
            id="paragraph"
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
            value={paragraph}
            onChange={(e) => setParagraph(e.target.value)}
            placeholder="Enter your paragraph here..."
            rows="6"
            required
          ></textarea>
        </div>

        <div className="flex justify-end">
          <button
            type="submit"
            className={`bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 ${
              loading ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={loading}
          >
            {loading ? "Generating..." : "Submit"}
          </button>
        </div>
      </form>

      {response && (
        <div className="mt-6 p-4 bg-gray-100 rounded-lg">
          <h3 className="text-lg font-bold text-gray-700 mb-2">AI Response:</h3>
          <p className="text-gray-800">{response}</p>
        </div>
      )}
    </div>
  );
};

export default GenerateWithAI;
