import React, { useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../../api/Constants";

const AddYearFactForm = () => {
  const [year, setYear] = useState("");
  const [fact, setFact] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${BASE_URL}/api/v1/year-facts`, { year: Number(year), fact });
      if (response.data.status === 200) {
        setMessage("Year fact added successfully!");
        setYear("");
        setFact("");
      } else {
        setMessage("Failed to add year fact. Please try again.");
      }
    } catch (error) {
      console.error("Error adding year fact:", error);
      setMessage("Error: Unable to submit the form.");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  return (
    <div className="max-w-4xl mx-auto mt-10 bg-white p-6 rounded-lg shadow-md">
      <h2 className="text-xl font-bold text-blue-600 mb-4">Add Year Fact</h2>
      <form onSubmit={handleSubmit} className="flex items-center space-x-4">
        <div className="flex-shrink-0">
          <input
            type="number"
            id="year"
            className="w-24 px-3 py-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
            value={year}
            onChange={(e) => setYear(e.target.value)}
            placeholder="Year"
            required
          />
        </div>

        <div className="flex-grow">
          <textarea
            id="fact"
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500"
            value={fact}
            onChange={(e) => setFact(e.target.value)}
            onKeyDown={handleKeyDown} // Call API on Enter
            placeholder="Enter a historical fact"
            rows="1" // Single row to save space
            required
          ></textarea>
        </div>

        <button
          type="submit"
          className="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
        >
          Add Fact
        </button>
      </form>

      {message && (
        <div className="mt-4 text-center text-sm text-gray-600">{message}</div>
      )}
    </div>
  );
};

export default AddYearFactForm;
