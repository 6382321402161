import React, { useState, useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../../api/Constants";

const YearFactsTable = () => {
  const [facts, setFacts] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const fetchYearFacts = async (pageNumber) => {
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/api/v3/year-facts`, {
        params: {
          page: pageNumber,
        },
      });
      const newFacts = response.data?.data?.facts || [];
      setFacts((prevFacts) => [...prevFacts, ...newFacts]);
    } catch (error) {
      console.error("Error fetching year facts:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchYearFacts(page);
  }, [page]);

  const handleRefresh = () => {
    setPage(1);
    setFacts([]);
    fetchYearFacts(1);
  };

  const handleDelete = async (factId) => {
    try {
      await axios.delete(`${BASE_URL}/api/v1/year-facts/${factId}`);
      setFacts(facts.filter((fact) => fact._id !== factId));
    } catch (error) {
      console.error("Error deleting fact:", error);
    }
  };

  return (
    <div className="max-w-4xl mx-auto mt-10 bg-white p-6 rounded-lg shadow-md">
      <h2 className="text-2xl font-bold text-blue-600 mb-4">Year Facts</h2>
      <div className="flex justify-between mb-4">
        <button onClick={handleRefresh} className="text-blue-600 cursor-pointer hover:font-bold">
          Refresh
        </button>
      </div>
      <table className="w-full border-collapse border border-gray-300">
        <thead>
          <tr className="bg-blue-500 text-white">
            <th className="border border-gray-300 px-4 py-2">Year</th>
            <th className="border border-gray-300 px-4 py-2">Fact</th>
            <th className="border border-gray-300 px-4 py-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {facts.map((fact, index) => (
            <tr key={index} className="hover:bg-gray-100">
              <td className="border border-gray-300 px-4 py-2">{fact.year}</td>
              <td className="border border-gray-300 px-4 py-2">{fact.fact}</td>
              <td className="border border-gray-300 px-4 py-2">
                <button
                  onClick={() => handleDelete(fact._id)}
                  className="text-red-600 hover:font-bold"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {loading && (
        <div className="text-center mt-4">
          <p className="text-blue-500">Loading more facts...</p>
        </div>
      )}
    </div>
  );
};

export default YearFactsTable;
