import * as React from "react";
import {
  Chip,
  Typography,
  Box,
  Stack,
  TextField,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { useState, useEffect } from "react";
import MainLayout from "../navigation/MainLayout";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const HomePage = () => {
  // Load tasks from localStorage if available
  const loadTasksFromLocalStorage = () => {
    const storedTasks = localStorage.getItem("tasks");
    return storedTasks ? JSON.parse(storedTasks) : [];
  };

  const [messages, setMessages] = useState(loadTasksFromLocalStorage);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);

  // Function to get relative time (e.g., "3 hours ago", "4 days ago")
  const getRelativeTime = (timestamp) => {
    const now = new Date();
    const taskTime = new Date(timestamp);
    const diff = now - taskTime;

    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
      return `${days} day${days > 1 ? "s" : ""} ago`;
    } else if (hours > 0) {
      return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    } else {
      return `${seconds} second${seconds > 1 ? "s" : ""} ago`;
    }
  };

  const addMessage = (message) => {
    const newMessage = {
      type: "info",
      message,
      timestamp: new Date().toISOString(), // Store the time when the task is created
      completed: false,
    };

    const newMessages = [...messages, newMessage];
    setMessages(newMessages);

    // Store the updated messages in localStorage
    localStorage.setItem("tasks", JSON.stringify(newMessages));
  };

  const handleMenuClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setSelectedTask(index);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedTask(null);
  };

  const handleMarkCompleted = () => {
    const updatedMessages = [...messages];
    updatedMessages[selectedTask].completed = true;

    // Remove the completed task from the list
    const filteredMessages = updatedMessages.filter((_, i) => i !== selectedTask);

    setMessages(filteredMessages);
    localStorage.setItem("tasks", JSON.stringify(filteredMessages));
    handleMenuClose();
  };

  return (
    <MainLayout>
      <Box mt={2}>
        <Typography variant="h6" mb={2}>
          What's on your mind!
        </Typography>
        <TextField
          variant="outlined"
          label="New Task"
          fullWidth
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              addMessage(e.target.value);
              e.target.value = "";
            }
          }}
          sx={{ marginBottom: 2 }}
        />
        <Stack direction="row" flexWrap="wrap">
          {messages.map((msg, index) => (
            <Box key={index} display="flex" alignItems="center">
              <Chip
                label={msg.message}
                clickable
                color={msg.completed ? "primary" : "default"}
                sx={{
                  textDecoration: msg.completed ? "line-through" : "none",
                  mr: 2
                }}
                onClick={(e) => handleMenuClick(e, index)}
              />
             
            </Box>
          ))}
        </Stack>

        {/* Menu for Task Options */}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleMarkCompleted}>Mark as Completed</MenuItem>
        </Menu>
      </Box>
    </MainLayout>
  );
};

export default HomePage;
