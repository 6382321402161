import React from "react";
import MainLayout from "../../navigation/MainLayout";
import AddYearFactForm from "./AddYearForm";
import YearFactsTable from "./YearFactsTable";
import GenerateWithAI from "./GenerateWithAI";

function Home() {
  return <MainLayout>

    <div>
      <div>Era Expert</div>
      <AddYearFactForm/>
      <GenerateWithAI/>
      <YearFactsTable/>
    </div>
  </MainLayout>;
}

export default Home;
